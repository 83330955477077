/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import QuizGoalsPageInfo from '../../molecules/QuizGoalsPageInfo/QuizGoalsPageInfo'
import QuizGoalsPageForm from '../../molecules/QuizGoalsPageForm/QuizGoalsPageForm'

interface Props {
  className: string
  handleShowPage: (page: string) => void
  handleModalOpen: () => void
}

export default function QuizGoalsPage ({ className, handleShowPage, handleModalOpen }: Props): JSX.Element {
  return (
    <div className={className}>
        <div className='flex justify-end w-full'>
            <button onClick={handleModalOpen}><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-zoom-question" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#5F31B3" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
              <path d="M21 21l-6 -6" />
              <path d="M10 13l0 .01" />
              <path d="M10 10a1.5 1.5 0 1 0 -1.14 -2.474" />
            </svg>
            </button>
        </div>
      <QuizGoalsPageInfo className='flex flex-col justify-center items-center relative visible_1' />
      <QuizGoalsPageForm handleShowPage={handleShowPage} className='w-auto md:w-full lg:w-[582px] flex flex-wrap justify-center items-center mt-5 relative visible_1' />
    </div>
  )
}
