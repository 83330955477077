/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  */
import HomeDesktopImg from '../../../img/home-img.png'
import HomeMobileImg from '../../../img/home-mobile.png'
import Image from '../../atoms/Image/Image'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'app/hooks'
import { Link } from 'react-router-dom'
import Button from 'components/atoms/Button/Button'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import EndSubscriptionModal from '../ProfileForm/Modals/EndSubscriptionModal'
import EndTrialModal from '../ProfileForm/Modals/EndTrialModal'

interface Props {
  className: string
  onScrollDown: () => void
}

export default function HeadersNonSignInUsers ({ className, onScrollDown }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const [homeImage, setHomeImage] = useState(HomeDesktopImg)
  const [userSignedIn, setUserSignedIn] = useState('')
  const [userSignedInUrl, setUserSignedInUrl] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false)

const toggleTrialModalState = () => {
    const subscription = currentUser?.subscription[0]
    const today = new Date()
    const todayDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)
    setIsTrialModalOpen(!!(subscription && subscription.valid_until < todayDate && subscription.type === -1))
}

 const toggleModal = () => {
    if (currentUser && currentUser.subscription && currentUser.subscription.length > 0) {
      if (!currentUser.subscription[0].is_active) {
        setIsModalOpen(true)
      } else {
        setIsModalOpen(false)
      }
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

    const closeTrialModal = () => {
        setIsTrialModalOpen(false)
    }


  const updateScreenWidth = () => {
    setIsScreenWidthAbove768(true)
    if (window.innerWidth < 600) {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.has_finished_quiz) {
        setUserSignedIn('Go to weekly goals')
        setUserSignedInUrl('/all-weekly-goals')
      } else if (!currentUser?.has_finished_steps) {
          setUserSignedIn('Resume registration')
          setUserSignedInUrl('/quiz')
      } else {
        setUserSignedIn('Go to dashboard')
        setUserSignedInUrl('/branda-score')
      }
      if(currentUser.subscription && currentUser.subscription.length > 0) {
        setIsModalOpen(!currentUser?.subscription[0].is_active)
      }

        toggleTrialModalState()

    } else {
      setUserSignedIn('Start My Free Trial!')
      setUserSignedInUrl('/quiz')
    }
  }, [currentUser])

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  useEffect(() => {
    setHomeImage(isScreenWidthAbove768 ? HomeDesktopImg : HomeMobileImg)
  }, [isScreenWidthAbove768])

  return (
    <div className={className}>
      <Image className="absolute h-auto sm:h-full w-full -z-1 object-cover object-top top-0" src={homeImage} />
      <div className='flex flex-col md:flex-row justify-end md:justify-between md:items-end lg:items-center md:h-full h-[75%] mt-28 md:mb-32' style={{ marginBottom: '10rem' }}>        <div className="flex flex-col items-start">
          <div className='main_header_home text-white ml-2.5 md:ml-20 font-bold header-big-text'>Hi</div>
          <div className='hidden lg:block main_header_home text-white ml-5 md:ml-20 md:pl-2.5 font-bold text-7xl mb-4'>I'm Branda</div>
          <div className='block lg:hidden main_header_home text-white ml-5 md:ml-20 md:pl-2.5 text-[1.7rem]'>I'm Branda</div>
          <div className='hidden lg:block text-black ml-5 md:ml-20 md:pl-2.5 text-xl'>Your Personal Branding AI Coach.</div>
          <div className='block lg:hidden text-black ml-5 md:ml-20 md:pl-2.5 mt-12'>Your Personal <br /> Branding AI Coach.</div>
          <Link to={userSignedInUrl} className='ml-5 mb-2 md:mb-0 md:ml-20 md:pl-2.5'>
          <Button
            text={userSignedIn}
            className="brand_me_up_and_quiz_button text-white font-normal rounded-lg text-sm md:text-lg p-3 md:px-6 md:py-4 mt-4 md:mt-10 text-center"
          />
        </Link>
        </div>
        <div className="flex justify-center md:hidden relative -mb-[15px] mt-[15px]">
          <KeyboardDoubleArrowDownIcon className="text-white arrow-down absolute" onClick={onScrollDown} />
        </div>
      </div>
      <EndSubscriptionModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onCloseModal={closeModal}
      />
    <EndTrialModal
        isOpen={isTrialModalOpen}
        onCloseModal={closeTrialModal}
    />
    </div>
  )
}
