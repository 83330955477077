/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setSocialMediaScores } from 'app/quiz/userSocialMedia'
import { users } from 'app/services/Api'
import { setCurrentUser } from 'app/quiz/quizSlice'
import { toast } from 'react-toastify'
import { Instagram, Facebook, LinkedIn } from '@mui/icons-material/'
import VideoPlayer from '../../Player/VideoPlayer'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  socialMedia: string
  submitText?: string
  showClose?: boolean
}

const videoUrl = 'ScoreWsub.mp4'
const mobileVideoUrl = 'ScoreMsub.mp4'

const transcriptContent = `
  <div>
    <h2 style="font-size: 24px; margin-bottom: 10px;">Your Brand Score</h2>
    <p style="font-size:12px;">You're amazing! <br/>
    Did you like your Branda?<br/><br/>
    
    Anyways, we're almost done...<br/>
    Before sending your personalized plan, let's try to assess your current brand performance! <br/>
    In the next step, please choose your active Social Media profiles or the ones you plan to post from. Choose at least two platforms. Your personal FaceBook account is mandatory - because this is where Personal Branding can actually get Personal!<br/><br/>
    
    Afterwards, please check your profiles and fill in how many posts do you publish on average every week and add how many followers or friends you currently have on each account. I've already broken down the Types of posts for you. If there are posts that you don't publish weekly, you can fill in decimal numbers.  Again, this is only a one-time process!<br/><br/>
    
    You might ask, why do I even need this score?<br/>
    Well, every process has a starting point. This is how I can make sure you'll be able to accurately assess your current brand level, benchmark your score to other industry peers, and visually track your progress! <br/>
    You will also get lots of cool insights!<br/>
    It will take you a minute and, believe me, It's worth it!<br/><br/>
    
    Ready to check your score?!
    </p>
  </div>
`

export default function SocialsPopUp ({ isOpen, onClose, onConfirm, socialMedia, submitText = 'Next', showClose = false }: Props) {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const facebookChecked = useAppSelector((state) => state.userSocialMediaReducer.facebook)
  const facebookProChecked = useAppSelector((state) => state.userSocialMediaReducer.facebookpro)
  const instagramChecked = useAppSelector((state) => state.userSocialMediaReducer.instagram)
  const instagramProChecked = useAppSelector((state) => state.userSocialMediaReducer.instagrampro)
  const [isFormValid, setIsFormValid] = useState(false)
  const [submissionAttempted, setSubmissionAttempted] = useState(false)
  const titleColor = (socialMedia === 'Instagram' || socialMedia === 'InstagramPro') ? 'text-pink-500'
    : (socialMedia === 'Linkedin') ? 'text-sky-500' : 'text-blue-500'
  const buttonColor = (socialMedia === 'Instagram' || socialMedia === 'InstagramPro') ? 'bg-pink-700'
    : (socialMedia === 'Linkedin') ? 'bg-sky-600' : 'bg-blue-700'
  const inputBorderColor = (socialMedia === 'Instagram' || socialMedia === 'InstagramPro') ? 'border-pink-500'
    : (socialMedia === 'Linkedin') ? 'border-sky-500' : 'border-blue-300'

  const iconColor = (['Instagram', 'InstagramPro'].includes(socialMedia)) ? '#EC4899FF'
    : (socialMedia === 'Linkedin') ? '#0EA5E9FF' : '#3B82F6FF'

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [showTranscript, setShowTranscript] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)

  let titleText = ''
  let icon = null

  if (socialMedia === 'Instagram') {
    titleText = 'Instagram Personal Profile'
    icon = <Instagram />
  } else if (socialMedia === 'InstagramPro') {
    titleText = 'Instagram Business Profile'
    icon = <Instagram />
  } else if (socialMedia === 'Facebook' || socialMedia === 'FacebookPro') {
    titleText = 'Facebook Personal Profile'
    icon = <Facebook />
  } else {
    titleText = 'Linkedin Personal'
    icon = <LinkedIn />
  }

  const questions = [
    { label: 'No. of posts', value: 'number_of_posts', type: 'hidden' },
    { label: socialMedia === 'Linkedin' ? 'Video' : 'Video/Reel/Story', value: 'count_video_reel_story', type: 'text' },
    { label: 'Carousel/Image Slider', value: 'count_carousel', type: 'text' },
    { label: 'Text + Image', value: 'count_text_image', type: 'text' },
    { label: socialMedia === 'Facebook' || socialMedia === 'FacebookPro' ? 'Page followers/Friends' : 'Page followers/Connections', value: 'no_followers', type: 'text' }
  ]
  const [filteredQuestions, setFilteredQuestions] = useState(questions)

  const [inputValues, setInputValues] = useState<Record<string, number | null>>(
    questions.reduce((acc, question) => ({ ...acc, [question.value]: null }), {})
  )

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmissionAttempted(false)
    const data = e.target
    const key = data.dataset.key

    let editedValue: number | null
    const inputValue = data.value.trim()
    if (/^[-+]?\d*\.?\d+$/.test(inputValue)) { // Check if input is a valid decimal number
      editedValue = parseFloat(inputValue)
    } else {
      editedValue = null
    }

    setInputValues(prevInputValues => ({
      ...prevInputValues,
      [key as string]: editedValue
    }))
  }

  useEffect(() => {
    const validatedFields = ['count_video_reel_story', 'count_carousel', 'count_text_image', 'no_followers']

    if (socialMedia === 'Instagram' || socialMedia === 'InstagramPro') {
      setFilteredQuestions(questions)
    }

    const isValid = validatedFields.every((field) => inputValues.hasOwnProperty(field) && inputValues[field] !== undefined && inputValues[field] !== null)
    setIsFormValid(isValid && submissionAttempted)
  }, [inputValues, isFormValid])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setSubmissionAttempted(true)

    const validatedFields = ['count_video_reel_story', 'count_carousel', 'count_text_image', 'no_followers']

    const isValid = validatedFields
      .every((field) => inputValues.hasOwnProperty(field) && inputValues[field] !== undefined && inputValues[field] !== null && typeof inputValues[field] !== 'string')

    // Set no_followers to 0 if it's not already in inputValues
    if (!inputValues.hasOwnProperty('no_followers')) {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        no_followers: 0
      }))
    }

    if (!isValid) {
      toast.error('Please use only digits in ALL fields 🙏')
      return
    }

    const numberOfPosts = Object.entries(inputValues).filter(([key]) => key !== 'no_followers').reduce((sum, [, value]) => sum + (value || 0), 0)
    let profile_type = 'Personal'

    if ((socialMedia === 'Instagram' || socialMedia === 'InstagramPro') && instagramProChecked) {
      if (instagramChecked && !instagramProChecked) {
        profile_type = 'Personal'
      } else {
        profile_type = 'Business'
      }
    }

    if ((socialMedia === 'Facebook' || socialMedia === 'FacebookPro') && facebookProChecked) {
      if (facebookChecked && facebookProChecked) {
        profile_type = 'Personal'
      } else {
        profile_type = 'Business'
      }
    }

    const socialMediaScoresPayload = {
      ...inputValues,
      number_of_posts: numberOfPosts,
      platform: socialMedia === 'InstagramPro' ? 'Instagram' : socialMedia === 'FacebookPro' ? 'Facebook' : socialMedia,
      profile_type,
      user_id: currentUser?.id || 0
    }

    dispatch(setSocialMediaScores([socialMediaScoresPayload]))
    await users.updateUser(currentUser?.id || 0, socialMediaScoresPayload)

    const getUser = async () => {
      await users.getCurrentUser(true).then((response) => {
        if (!response.response) {
          dispatch(setCurrentUser(response))
        } else {
          dispatch(setCurrentUser(null))
        }
      })
        .catch((err) => {
          console.log(err)
        })
    }

    void getUser()

    window.scrollTo(0, 0)
    onConfirm()
  }

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleModalOpen = (): void => {
    setModalOpen(true)
  }
  const handleCloseModal = (): void => {
    setModalOpen(false)
  }
  const toggleTranscript = (): void => {
    setShowTranscript(!showTranscript)
  }

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex={-1}
      aria-hidden="true"
      className={`${isOpen ? '' : 'hidden'} flex fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full backdrop-blur-sm`}
    >
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="relative p-2 h-full overflow-y-scroll no-scrollbar md:overflow-hidden md:h-auto">
        <div className="branda-score w-[350px] md:w-[600px] p-4 bg-gray-100 rounded-3xl shadow dark:bg-gray-700">

          <div className='flex justify-end w-full'>
            <button onClick={handleModalOpen}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-zoom-question mr-4"
                   width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke={iconColor} fill="none"
                   strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"/>
                <path d="M21 21l-6 -6"/>
                <path d="M10 13l0 .01"/>
                <path d="M10 10a1.5 1.5 0 1 0 -1.14 -2.474"/>
              </svg>
            </button>
          </div>

          {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-5 rounded-lg max-w-2xl w-full h-[87%] md:h-[unset]">
                  <div className='flex justify-end' >
                    <button className="absolute text-white text-xl" onClick={handleCloseModal}style={{ marginTop: '-22px' }}>
                      &times;
                    </button>
                  </div>
                  {!showTranscript
                    ? (
                          <>
                            <VideoPlayer filename={isMobile ? mobileVideoUrl : videoUrl} />
                          </>
                      )
                    : (
                          <div>
                            <div className="transcript-content bg-[#ffffffb8] rounded p-2" style={{ height: '90%', overflowY: 'auto' }}>
                              <div dangerouslySetInnerHTML={{ __html: transcriptContent }} />
                            </div>

                          </div>
                      )}
                  <div className="flex justify-between mt-4">
                    <button
                        className="px-4 py-2 bg-black rounded text-white w-28"
                        onClick={toggleTranscript}
                    >
                      {showTranscript ? 'Video' : 'Transcript'}
                    </button>
                    <button
                        className="px-4 py-2 bg-gray-200 rounded w-28"
                        onClick={handleCloseModal}
                    >
                      Skip
                    </button>
                  </div>
                </div>
              </div>
          )}

          {showClose && (
              <div className="flex justify-end">
                <button
                    onClick={onClose}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="static-modal"
                >
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
          )}
          <form
              className="w-full"
              autoComplete="off"
              onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-center justify-center mb-6 mt-5 md:mt-2">
              <span className='font-black font-bold text-center md:text-left text-xl md:text-2xl mb-1 md:mb-0'>
                Fill in your weekly post type and number
              </span>
              <p className={`font-black font-semibold text-sm md:text-lg ${titleColor}`}>
                <span style={{ textDecoration: 'underline' }}> {icon} {titleText}</span>
              </p>
              {(!facebookChecked && facebookProChecked && titleText === 'Personal Facebook') && (
                  <p className='mt-5 bg-white p-5 text-sm text-center rounded-xl'>
                    Personal Branding is focusing on your Personal Facebook account’s network. Please fill in the
                    relevant data for this specific account
                  </p>
              )}
            </div>
            <div
                className="flex flex-col justify-center items-center md:items-start md:justify-between mt-2 p-2 md:p-3 rounded-t gap-4">
              {filteredQuestions.map((question, index) => (
                  <div key={index}
                       className="flex flex-col md:flex-row items-center justify-center md:justify-between w-full">
                    {question.value !== 'number_of_posts' && (
                        <p className='font-medium text-base md:text-lg text-black mb-2 md:mb-0 md:mr-5'>
                          {question.label}
                        </p>
                    )}
                    <input
                        type={question.type}
                        name={`input_${index}_${question.value.replace(/\s+/g, '_')}`}
                        data-key={question.value}
                        onChange={(e) => {
                          handleInputValue(e)
                        }}
                        className={`text-center items-center md:ml-auto border-solid border-2 rounded-lg p-1 rounded w-40 focus:outline-none focus:border-solid focus:border-2 ${inputBorderColor} ${submissionAttempted && (inputValues[question.value] === null || inputValues[question.value] === undefined) ? 'border-red-400' : ''}`}
                    />

                  </div>
              ))}
            </div>
            <div className="p-2 md:px-5 md:pt-10 md:pb-3 space-y-4">
              <div className="flex justify-center">
                <button
                    className={`w-32 md:w-40 py-3 text-sm md:text-lg mt-7 md:mt-4 font-semibold text-white rounded-lg ${buttonColor}`}
                    type="submit"
                >
                  {submitText}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
